import "../CSS/intro.css";

function Intro() {
  return (
    <div className="intro-container">
      <h4>Hi,</h4>
      <h1 className="big-heading">Welcome.</h1>
      <br />
      <p>
        On this site you will find information about me, my skills, and my
        projects. I’m a software engineer specializing in building Applications
        in C#/NET and have recently started working with React.
      </p>
      <p>
        This site is coded in HTML5/CSS, JavaScript, jQuery, React, Bootstrap
        and Nodejs. It is my first React project! It is hosted for free on Azure
        Cloud. The source code can be found on GitHub.
      </p>
      <p>If you have any questions feel free to contact me!</p>
    </div>
  );
}

export default Intro;
